import { Durations } from '@/constants/durations'
import { paths } from '@/constants/paths'
import { Seo } from '@/layout/Seo'
import { useCanonicalUrl } from '@/layout/Seo/useCanonicalUrl'
import { DefaultLayout, NextPageWithLayout } from '@/layouts'
import { Downloadable, PressRelease, Project, TeamMember, getPressPageContent } from '@/services/PressService'
import { getGenericStaticPaths } from '@/utils/GetGenericStaticPaths'
import { getStaticPropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { loadTranslations } from '@/utils/translate/translate-server'
import { PressView } from '@/views/PressView'

interface PressProps {
  brands: Project[]
  downloadables: Downloadable[]
  pressReleases: PressRelease[]
  teamMembers: TeamMember[]
}

const PressPage: NextPageWithLayout<PressProps> = ({ brands, downloadables, pressReleases, teamMembers }) => {
  const url = useCanonicalUrl(paths.press.index)
  const title = 'Press | Angel Studios'
  const description = 'Angel Studios Press Room'
  const openGraph = {
    title,
    description,
    url,
    type: 'website',
  }
  return (
    <>
      <Seo canonical={url} openGraph={openGraph} title={title} description={description} />
      <PressView
        brands={brands}
        downloadables={downloadables}
        pressReleases={pressReleases}
        teamMembers={teamMembers}
      />
    </>
  )
}

PressPage.getLayout = (page) => {
  return <DefaultLayout isDarkMode={false}>{page}</DefaultLayout>
}

export const getStaticProps = getStaticPropsErrorHandler(
  { filePath: 'pages/[locale]/press/index.tsx' },
  async ({ locale, preview }) => {
    const { brands, downloadables, pressReleases, teamMembers } = await getPressPageContent(preview, locale)
    return {
      props: {
        brands,
        downloadables,
        pressReleases,
        teamMembers,
        ...(await loadTranslations(locale, ['common', 'account'])),
      },
      revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
    }
  },
)

export const getStaticPaths = getGenericStaticPaths

export default PressPage
